<template>
  <div id="team">
    <h1>Erreur 404</h1>
    <h2 style="text-align: center;">Contenu non trouvée.</h2>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: "404",
    titleTemplate: '%s - DRiMSCREATiVE '
  },
  data() {
    return {}
  },
}
</script>
