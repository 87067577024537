<template>
  <div id="talents-box">
    <template v-if="paginatedTalents && paginatedTalents.length">
      <responsive-grid :data="paginatedTalents" :numbers-of-colums="breakpoint.columns">
        <template v-slot:default="data">
          <card
              :key="data.talent.uuid"
              :image="getTalentImage(data.talent)"
              :image-alt="''"
              :preload-image="data.index !== 0"
              :text="config.jobs[data.talent.jobs[0]]"
              :title="getTalentName(data.talent)"
              @click="goToTalentByIndex($event, data.talent.filterIndex)"
          >
            <template v-if="secured">
              <router-link
                  class="link"
                  :to="{name:'admin_talents_profile', params: {user: data.talent.screenName}}">Gérer le profil
              </router-link>
            </template>
          </card>
        </template>
      </responsive-grid>
    </template>
    <template v-else>
      <div class="no-talent">Pas de talent correspondant</div>
    </template>
  </div>
</template>
<script>
import AsyncImage from '@/components/AsyncImage'
import Card from '@/components/Card'
import Loader from '@/components/Loader'
import Pagination from '@/components/pagination'
import ResponsiveGrid from '@/components/responsiveGrid'
import {mapState} from 'vuex'

export default {
  name: 'talents',
  components: {Card, AsyncImage, ResponsiveGrid, Pagination, Loader},
  data () {
    return {
      pagination: 0,
      talentsColumns: 1,
      numberOfTalents: 10,
      paginationLocked: false,
      activeLoader: false,
      paginatedTalents: {},
    }
  },
  computed: {
    ...mapState(['breakpoint', 'search', 'config', 'filteredTalents', 'currentTalent', 'secured']),
    columnedTalents () {
      const cols = []
      for (let i = 0, col = 0, modulo; i < this.paginatedTalents.length; i++, col++) {
        modulo = col % this.talentsColumns
        if (!cols[modulo]) {
          cols[modulo] = []
        }
        cols[modulo].push(this.paginatedTalents[i])
      }
      return cols
    },
  },
  methods: {
    async goToTalentByIndex (event, index) {
      let talent = await this.$store.dispatch('GET_CURRENT_TALENT_BY_INDEX', index)
      this.$nextTick(() => {
        if (event.ctrlKey) {
          this.$router.push({
            name: 'talent',
            params: {
              name: talent.screenName
            },
            query: {
              preview: true
            }
          })
        } else {
          this.$router.push({name: 'talent', params: {name: talent.screenName}})
        }

      })
    },
    getTalentImage (talent) {
      return `http://static.drimscreative.com${talent.avatar2 || talent.avatar}`
    },
    getTalentName (talent) {
      return talent.displayName
    },
    getTalentJob (talent) {
      return talent.domains ? talent.domains.split(',')[0] : talent.profilType
    },
    async setCurrentTalent (talent) {
      await this.$store.dispatch('SET_CURRENT_TALENT', talent)
    },
    setPagination (pageIndex, startIndex, endIndex) {
      // let talents = Array.from(this.talents)
      this.paginatedTalents = this.talents.slice(startIndex, endIndex)
      this.pagination = pageIndex
    },
    setColumnNumber () {
      const w = window.innerWidth
      switch (true) {
        case w > 1024:
          this.talentsColumns = 4
          this.numberOfTalents = 40
          break
        case w > 750:
          this.talentsColumns = 3
          this.numberOfTalents = 30
          break
        case w > 500:
          this.talentsColumns = 2
          this.numberOfTalents = 20
          break
        default:
          this.talentsColumns = 1
          this.numberOfTalents = 10
      }
    },
  },
  props: {
    talents: {
      type: Array,
      required: true,
    },
  },
  watch: {
    pagination () {
      /*this.$scrollTo('#talents-box', 300, {
        offset: -50,
      })*/
    },
    columnedTalents () {
      this.activeLoader = true
      setTimeout(() => {
        this.activeLoader = false
      }, 400)
    },
    talents: {
      immediate: true,
      handler (talents) {
        this.paginatedTalents = talents
      },
    },
  },
  beforeMount () {
    this.pagination = this.search.pagination
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.setColumnNumber()
    })
    this.setColumnNumber()
  },
}
</script>
<style lang="scss">
.talents {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 36px;
  text-align: center;
  max-width: 1260px;
  margin: 0 auto;

  &.talents-grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.talents-grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.talents-grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.talent {
  margin-bottom: 70px;
  width: 240px;
}

.talent-avatar {
  background-size: cover;

  img.dynamic-image-loaded {
    width: 100%;
  }
}

.talent-name {
  display: block;
  font-size: 26px;
  margin-top: 20px;
}

.talent-type {
  font-size: 16px;
}

.talent-link:hover {
  text-decoration: none !important;
}

.talents-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  list-style: none;
  margin: 50px 0;
  user-select: none;

  .inactive {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }


  .pagination-page {
    min-width: 23px;
    min-height: 37px;
    max-width: 23px;
    max-height: 37px;
    text-align: center;
    position: relative;
    display: block;
    cursor: pointer;
    margin-right: 40px;

    &:last-of-type {
      margin-right: 0;
    }

    &.current, &:hover {
      background: #D6F2C7
    }

    & > span {
      position: relative;
      z-index: 1;
      line-height: 37px;
      min-width: 23px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
  opacity: 1;
  transform: scale(1);
}

.fade-enter, .fade-leave-to
  /* .fade-leave-active avant la 2.1.8 */
{
  opacity: 0;

}

.no-talent {
  font-size: 32px;
  font-weight: 500;
  margin: 0px 0;
  text-align: center;
}

.responsive-grid-column {
  .talent {
    width: 100%;
    max-width: 320px;
    margin: 0 auto 70px;
  }
}

.responsive-cols-1,
.responsive-cols-2 {
  .talent {
    margin: 0 auto 35px;
  }
}

#talents-box {
  text-align: center;
  padding-bottom: 11px;

  .dcr-card .dynamic-image-image {
    width: 100%;
  }
}
</style>
