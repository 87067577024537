<template>
  <footer id="footer">
    <div id="footer-top">
      <ul class="list">
        <li>
          <a :href="`mailto:${emailLink}`" itemprop="email" target="_blank">{{ config.contact.email }}</a>
        </li>
        <li>
          <a :href="`tel:${phoneLink}`" itemprop="telephone">{{ config.contact.phone }}</a>
        </li>
        <li itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
          <a :href="mapLink" target="_blank" rel="noreferrer">
            <span itemprop="streetAddress">{{ config.contact.address.street }}</span>
            <span role="presentation">, </span>
            <span itemprop="postalCode">{{ config.contact.address.zipcode }} </span>
            <span itemprop="addressLocality">{{ config.contact.address.city }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div id="footer-separator" role="presentation"></div>
    <div id="clients">
      <strong>Quelques-uns de nos clients</strong>
      <template v-if="device.isMobile">
        <ul class="clients-list" name="flip-list" id="clientMobile">
          <li
              v-for="(client, index) of clients"
              :key="client.name"
              class="clients-list-item"
              :class="index === currentIndex ? 'active': ''"
              :data-index="index">
            <img :src="getClientImage(client)" class="clients-list-item-image" alt="" :width="client.width"
                 :height="client.height"/>
            <span class="clients-list-item-name">{{ client.name }}</span>
          </li>
        </ul>
      </template>
      <template v-else>
        <transition-group class="clients-list" name="flip-list" tag="ul">
          <li v-for="client of clients" :key="client.name" class="clients-list-item">
            <img :src="getClientImage(client)" class="clients-list-item-image" alt="" :width="client.width"
                 :height="client.height"/>
            <span class="clients-list-item-name">{{ client.name }}</span>
          </li>
        </transition-group>
      </template>

    </div>
    <ul id="footer-links" class="inline-list space-between">
      <li>
        <router-link :to="{name: 'mentions'}">Mentions Légales</router-link>
      </li>
      <li>
        <router-link :to="{name: 'confidential'}">Politique de confidentialité</router-link>
      </li>
    </ul>
  </footer>
</template>
<script>
import BackButton from "@/components/BackButton";
import {mapState} from 'vuex'
import shuffle from 'lodash.shuffle'
import config from '@/assets/json/datas.json'

export default {
  name: 'Footer',
  components: {BackButton},
  data() {
    return {
      config,
      clients: shuffle([
        {
          name: 'Adopteunmec.com',
          image: 'adopteUnMec.png',
          width: 195,
          height: 40
        },
        {
          name: 'Ici Barbès',
          image: 'ici_barbes.png',
          width: 50,
          height: 57
        },
        {
          name: 'La Poste Mobile',
          image: 'laPosteMobile.png',
          width: 60,
          height: 41
        },
        {
          name: 'Manifik',
          image: 'manifik.png',
          width: 71,
          height: 50
        },
        {
          name: 'Primashop',
          image: 'primashop.png',
          width: 134,
          height: 30
        },
        {
          name: 'PUIG',
          image: 'puig.png',
          width: 112,
          height: 20
        },
        {
          name: 'Romance',
          image: 'romance.png',
          width: 50,
          height: 50
        },
        {
          name: 'Hungry & Foolish',
          image: 'hungry_foolish.jpg',
          width: 73,
          height: 40
        },
        {
          name: 'Guerlain',
          image: 'guerlain.jpg',
          width: 170,
          height: 40
        },
        {
          name: 'Bouygues Télécom',
          image: 'bt.jpg',
          width: 63,
          height: 50
        },
        {
          name: 'Clarins',
          image: 'clarins.jpg',
          width: 142,
          height: 50
        },
        {
          name: 'COTY',
          image: 'coty.jpg',
          width: 117,
          height: 40
        }
      ]),
      currentIndex: 0,
      TO: null
    }
  },
  methods: {
    getClientImage(client) {
      return `/img/clients/${client.image}`
    },
    shuffleClient() {
      this.clients = shuffle(this.clients)
    },
    startClient() {
      this.TO = setInterval(() => {
        let index = this.currentIndex + 1
        if (index > this.clients.length - 1) {
          index = 0
        }
        this.currentIndex = index
      }, 3000)
    }
  },
  computed: {
    ...mapState(['device']),
    mapLink() {
      return `https://maps.google.com/?q=${encodeURIComponent('23 rue des Jeûneurs, 75002 Paris')}`
    },
    images() {
      const clients = Array.from(this.clients)
      for (let i = clients.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        const temp = clients[i]
        clients[i] = clients[j]
        clients[j] = temp
      }
      return clients.map((image) => `@/assets/images/clients/${image}.jpg`)
    },
    emailLink() {
      return this.config.contact.email
    },
    phoneLink() {
      return this.config.contact.phone.replace(/\s/g, '')
    },
  },
  watch: {
    device: {
      immediate: true,
      handler() {
        if (this.device.isMobile) {
          this.startClient()
        } else {
          clearInterval(this.TO)
        }
      }
    }
  },
  destroyed() {
    clearInterval(this.TO)
  }
}
</script>
<style lang="scss">
@import "~@/assets/styles/_lists.scss";

#footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  #footer-top {
    .list {
      text-align: center;

      li [href] {
        color: $black;
      }
    }
  }

  #clients {
    min-width: 100%;

    strong {
      text-align: center;
      font-size: 16px;
      min-width: 100%;
      display: block;
      font-weight: 600;
      margin-bottom: 40px;
    }

    .clients-list {
      li img {
        opacity: 0.5;
        filter: grayscale(100);
        transition: opacity 300ms;
      }

      li:hover img {
        opacity: 1;
        filter: grayscale(0);
      }
    }

  }

  #footer-links {
    min-width: 100%;
    margin-bottom: 60px;

    [href] {
      font-size: 12px;
      white-space: nowrap;
    }
  }

  #footer-separator {
    min-width: 65px;
    max-width: 65px;
    height: 1px;
    background-color: $black;
    margin: 40px auto;
  }
}

.clients-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 30px;
  margin-bottom: 30px;
}

.is-mobile .clients-list {
  grid-template-columns: repeat(2, 1fr);
}

.clients-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  position: relative;
}

.clients-list-item-name {
  position: absolute;
  bottom: -30px;
  opacity: 0;
  transition: 250ms;
  font-size: 12px;
  display: none;
}

.clients-list-item:hover .clients-list-item-name {
  opacity: 1;
}

.flip-list-move {
  transition: transform 1s;
}

.is-mobile {
  .clients-list-item {
    margin: 10px;
  }

  .clients-list-item-name {
    display: none;
  }
}

#clientMobile {
  display: block;
  width: 100%;
  height: 120px;
  position: relative;

  li {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: 300ms;
    opacity: 0;
    margin: 0;

    &.active {
      opacity: 1;
    }
  }
}
</style>
