<template>
  <div :class="fillClass" class="logo">
    <router-link class="logo-link" title="Accueil" to="/">
      <component :is="logo" :width="width" class="logo-logo" itemprop="logo"></component>
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'Logo',
  data () {
    return {}
  },
  computed: {
    logo () {
      return require('@/assets/svg/logo.svg')
    },
    loader () {
      return require('@/assets/svg/loader.svg')
    },
    fillClass () {
      return this.fill
          ? `fill-${this.fill}`
          : (this.$route.meta && this.$route.meta.logo)
              ? `fill-${this.$route.meta.logo}`
              : 'nop'
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      default: '100%',
    },
    height: {
      default: 98,
    },
    fill: {
      type: String,
      required: false
    }
  }
}
</script>
<style lang="scss">
.logo {
  .logo-logo, path {
    transition: 250ms ease-out;
  }

  path {
    fill: black;
  }
}

.logo-link {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.fill-grey {
  path {
    fill: #f3f3f3;
  }
}
</style>
