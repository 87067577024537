import EventBus from "@/helpers/EventBus";
import store from "@/store";
import axios, {Method} from 'axios';
// @ts-ignore
import merge from 'lodash.merge';
// @ts-ignore
import qs from 'querystring';

const sleep = async function (duration: number = 1000) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

const buildErrorsMap = function (error: any) {
  if (!error.config || !error.config.data) {
    return false
  }
  let datas = JSON.parse(error.config.data)
  let message = error.response.data.message

  if (/^SQLSTATE/.test(message)) {
    let output: any = {}
    for (let key in datas) {
      if (datas.hasOwnProperty(key)) {
        let value = datas[key]
        if (message.indexOf(key) > 0) {
          output[key] = false
        } else {
          output[key] = true
        }
      }
    }
    return Object.assign(error, {sql: output})
  } else {
    return error
  }
}

const interceptor = axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      let err = error.response ? error.response.data : null
      if (!err) {
        return Promise.reject(error);
      } else if (error.config.id === 'catch_no_error') {
        return Promise.resolve(error);
      } else if (err.status === 401 && error.config.id !== 'refresh_token') {
        await store.dispatch('SET_USER', null)
      } else if (err.error === 500 && err.message === 'Expired token') {
        await store.dispatch('SET_USER', null)
        EventBus.$once('onConnected', () => {
          console.log("onConnected event")
        })
        EventBus.$emit('token_expired')
      } else {
        return Promise.reject(error);
      }
    },
);
const buildEndpointURI = function (endpoint: string) {
  return endpoint;
};
const mergeConfig = function (config: any) {
  let output: any = {};
  output.id = config.id || 'fetch';
  output.data = config.data;
  output.params = Object.assign({}, config.params || {});
  output.headers = Object.assign({}, config.headers || {});
  if (store.state.user.token) {
    output.headers.authorization = `bearer ${store.state.user.token}`
  }
  return output;
};
const getErrorDescriptor = function (error: any) {
  const status = error.response ? error.response.status : null;
  const requestId =
      (error.config && error.config.headers && error.config.headers['X-request-id'])
          ? error.config.headers['X-request-id']
          : false;
  let errors = error.response.data.errors;
  return {
    status,
    id: requestId,
    message: JSON.stringify(errors),
  };
};
const api = {
  async GET(endpoint: string = 'about:blank', config = {}) {
    return await api.fetch(buildEndpointURI(endpoint), 'GET', mergeConfig(config));
  },
  async POST(endpoint: string = 'about:blank', config = {}) {
    return await api.fetch(buildEndpointURI(endpoint), 'POST', mergeConfig(config));
  },
  async PUT(endpoint: string = 'about:blank', config = {}) {
    return await api.fetch(buildEndpointURI(endpoint), 'PUT', mergeConfig(config));
  },
  async PATCH(endpoint: string = 'about:blank', config = {}) {
    return await api.fetch(buildEndpointURI(endpoint), 'PATCH', mergeConfig(config));
  },
  async DELETE(endpoint: string = 'about:blank', config = {}) {
    return await api.fetch(buildEndpointURI(endpoint), 'DELETE', mergeConfig(config));
  },
  async OPTIONS(endpoint: string = 'about:blank', config = {}) {
    return await api.fetch(buildEndpointURI(endpoint), 'OPTIONS', mergeConfig(config));
  },
  async fetch(url: string, method: Method = 'GET', config: any = {}) {
    store.commit("OPEN_LOADER", "Chargement...  ")
    let params = config.params ? qs.stringify(config.params) : '';
    let builtConfig = merge({}, {
      url: params.length ? url + '?' + params : url,
      method,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'X-Request-Id': config.id || 'default',
        'X-Client-Id': 'babe5a0f71e218e7ee1e5acb2e039fed',
        'X-Client-Secret': '51a38a0110da0e3bf9ce9b16159dea9b'
      },
    }, config);
    delete builtConfig.params;
    return await axios(builtConfig)
        .then((res: any) => {
          return res;
        })
        .catch((err: any) => {
          let buildErrors = buildErrorsMap(err)
          if (buildErrors) {
            throw buildErrors;
          }
          return buildErrors
        })
        .finally(() => {
          store.commit("CLOSE_LOADER")
        })
  },
};
export default api;
