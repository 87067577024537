<template>
  <form class="adminForm" @submit.prevent.stop="doConnect" id="connexionForm" ref="connection">
    <fieldset>
      <label class="labelTitle" for="login">Login</label>
      <div class="adminFormLine adminFormSpacerSmall">
        <input type="email" id="login" placeholder="login" name="login" required v-model="login">
      </div>
      <label class="labelTitle" for="password">Mot de passe</label>
      <div class="adminFormLine">
        <input type="password" id="password" placeholder="Mot de passe" name="pass" required v-model="password">
      </div>
      <div class="adminFormLine adminFormSubmit">
        <button type="submit" class="btBig">Envoyer</button>
      </div>
      <div class="danger" v-if="error">
        <strong>Login ou mot de passe incorrect.</strong>
      </div>
    </fieldset>
  </form>
</template>

<script>
export default {
  name: "ConnexionComponent",
  data () {
    return {
      error: false,
      login: '',
      password: ''
    }
  },
  props: {
    next: {
      type: Function,
      default: () => {
        // do nothing
      }
    }
  },
  methods: {
    async doConnect () {
      this.$refs.connection.classList.add('loading')
      let connect = await this.$store.dispatch('DO_CONNECTION', {
        login: this.login,
        pass: this.password,
      })
          .then(async (res) => {
            if (res.isAxiosError) {
              return Promise.reject(res)
            }
            this.error = false
            await this.$store.dispatch('SET_USER', res.data)
            this.$nextTick(() => {
              this.next()
            })
            return res.data
          })
          .catch(async (err) => {
            await this.$store.dispatch('SET_USER', null)
            this.error = true
            return err
          })
          .finally(() => {
            this.$refs.connection.classList.remove('loading')
          })
    }
  }
}
</script>


<style lang="scss">

#connexionForm {
  max-width: 100%;
  width: 400px;
  margin: 0 auto;

  .warn {
    text-align: center;
  }
}
</style>
