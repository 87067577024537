












































import ConnexionComponent from "@/components/Connexion.vue";
import FavoritesList from '@/components/favoritesList.vue';
import TalentTools from '@/components/TalentTools.vue';
import api from "@/helpers/api";
import Team from "@/views/Admin/teamPage.vue";
import Vue from 'vue';
import Header from '@/views/Header.vue';
import Footer from '@/views/Footer.vue';
import {mapState} from 'vuex';
import Panel from "@/components/Panel.vue";
import Loader from "@/components/Loader.vue";
import EventBus from "@/helpers/EventBus";

export default Vue.extend({
  name: 'app',
  data() {
    return {
      lastRoute: '',
      readyToShow: false
    }
  },
  components: {
    ConnexionComponent,
    Team,
    TalentTools,
    Loader,
    Panel,
    FavoritesList,
    Header,
    Footer,
  },
  methods: {
    enter(el: any) {
      setTimeout(() => {
        el.classList.add('route-current');
      }, 300);
    },
    leave(el: any) {
      el.classList.remove('route-current');
    },
    async afterConnect () {
      // @ts-ignore
      EventBus.$emit('onConnected')
      this.$bvModal.hide('connexion')
    }
  },
  watch: {
    appLoaded: {
      immediate: true,
      handler (loaded) {
        if (loaded) {
          setTimeout(() => {
            // @ts-ignore
            this.readyToShow = true
          }, 700)
        }
      },
    },
  },
  computed: {
    ...mapState(['appLoaded', 'currentMedia', 'device', 'isFavoriteOpen', 'user']),
    mediaModalActive() {
      return Boolean(this.currentMedia);
    },
    fx() {
      let fx = 'slide' // this.device.isDesktop ? 'slide' : 'nop';
      // @ts-ignore
      if (this.lastRoute === this.$route.name || (this.lastRoute && !this.lastRoute.length)) {
        fx = 'nop';
      }
      // @ts-ignore
      this.lastRoute = this.$route.name;
      return fx;
    },
  },
  mounted () {
    EventBus.$on('token_expired', () => {
      this.$bvModal.show('connexion')
    })
  }
});
