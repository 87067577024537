<template>
  <div :class="`${fluid ? 'responsive-fluid-cols-' : 'responsive-cols-'}${numbersOfColums}`" class="responsive-grid">
    <template v-for="column in columns">
      <div class="responsive-grid-column">
        <template v-for="(item, index) of column">
          <slot v-bind="{talent: item, index: index}"></slot>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'responsiveGrid',
  data () {
    return {
      cell: {
        uuid: 12,
      },
    }
  },
  computed: {
    columns () {
      const cols = []
      for (let i = 0; i < this.numbersOfColums; i++) {
        cols[i] = []
      }
      for (let i = 0, length = this.data.length; i < length; i++) {
        let index = i % this.numbersOfColums
        cols[index].push(this.data[i])
      }
      return cols
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    numbersOfColums: {
      type: Number,
      default: 3,
    },
    fluid: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style lang="scss">
$gapX: 34px;
$gapY: 17px;
$gap: 17px 34px;
.responsive-grid {
  display: inline-grid;
  grid-gap: $gap;
  grid-template-columns: 250px;
  margin: 0 auto;
}

@for $i from 2 through 6 {
  .responsive-fluid-cols-#{$i} {
    grid-template-columns: repeat($i, calc((100% / #{$i}) - ((#{$gapX} * (#{$i} - 1) / #{$i}))));
  }
}

@for $i from 1 through 6 {
  .responsive-cols-#{$i} {
    grid-template-columns: repeat($i, 225px);
  }
}

.responsive-cols-1 {
  grid-template-columns: auto;
}

.responsive-cols-2 {
  // grid-template-columns:repeat(2, auto);
}
</style>
