<template>
  <div v-if="media" class="media-box" @click.right.capture.prevent.stop>
    <template v-if="type === 'video'">
      <video :poster="media.thumb" :src="media.path" controls muted></video>
    </template>
    <template v-else-if="type === 'vimeo'">
      <iframe
          :src="vimeoURL"
          allow="autoplay; encrypted-media"
          allowfullscreen
          frameborder="0"></iframe>
    </template>
    <template v-else>
      <async-image
          :height="media.height"
          :observe-intersection="false"
          :url="path"
          :width="media.width"
          :alt="alt"
      ></async-image>
    </template>
  </div>
</template>
<script>
import AsyncImage from "@/components/AsyncImage";

export default {
  name: 'Media',
  components: {AsyncImage},
  data () {
    return {}
  },
  methods: {
    openMediaModal () {
      this.$store.commit('SET_CURRENT_MEDIA', this.media)
    },
  },
  computed: {
    type () {
      let type = this.media.type
      switch (type) {
        case 'video':
          if (/vimeo/i.test(this.path)) {
            return 'vimeo'
          } else {
            return type
          }
      }
      return type
    },
    path () {
      return `http://static.drimscreative.com${this.media.path}`
    },
    vimeoURL () {
      if (this.type !== 'vimeo') {
        return false
      }
      let path = this.media.path
      let id = path.replace('https://vimeo.com/', '')
      return `https://player.vimeo.com/video/${id}?title=0&amp;byline=0&amp;portrait=0&amp;color=3ab2ed`
    },
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
    alt: {
      type: String,
      default: ''
    }
  },
}
</script>
<style lang="scss">
.media-box img {
  background: #E2E2E2;
}

#mediaModal {
  .modal-dialog {
    min-height: 90vh;
    max-height: 90vh;
    min-width: 90vw;
    max-width: 90vw;
  }
}
</style>
