<template>
  <b-modal
      :id="id"
      :hide-footer="true"
      :hide-header="false"
      modal-class="modal-full"
  >
    <template slot="modal-header-close">
        <span class="header-mobile-button active">
          <span class="header-mobile-button-top"></span>
          <span class="header-mobile-button-middle"></span>
          <span class="header-mobile-button-bottom"></span>
        </span>
    </template>
    <slot></slot>
  </b-modal>
</template>
<script>
export default {
  name: 'eModal',
  data () {
    return {}
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
}
</script>
<style lang="scss">
.modal-full.modal {
  border: 0;
  width: 100vw;
  height: 100vh;

  .modal-dialog {
    margin: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;

    .modal-content {
      height: 100vh;
    }
  }

  .modal-header {
    border: none;
    padding: 1rem 1rem 0;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
