<template>
  <div :id="id" :class="isOpen ? 'active' : 'not-active'" class="panel">
    <button aria-label="Fermer le panneau" class="panel-close" @click="closePanel(id)">
      <component :is="closeImage" height="20" width="20"></component>
    </button>
    <slot></slot>
  </div>
</template>

<script>
import EventBus from "@/helpers/EventBus";

let zIndex = 10000
let instances = []
export default {
  name: "Panel",
  data () {
    return {
      isOpen: false
    }
  },
  props: {
    id: String,
    required: true
  },
  computed: {
    closeImage () {
      return require('@/assets/svg/croix.svg')
    },
    openedPanelsLength () {
      return instances.length
    }
  },
  methods: {
    openPanel (id) {
      if (id === this.id) {
        this.isOpen = true
        instances.push(this.id)
        this.$el.style.zIndex = ++zIndex
        EventBus.$emit('panel-changed')
      } else {
        this.closePanel()
      }
    },
    closePanel (id) {
      if (id === this.id ) {
        this.isOpen = false
        instances = instances.filter((inst) => inst !== id)
        EventBus.$emit('panel-changed')
      } else if (id === undefined) {
        this.isOpen = false
        instances = instances.filter((inst) => inst !== this.id)
        EventBus.$emit('panel-changed')
      }
    },
    closeAll () {
      EventBus.$emit("panel-close")
    },
    emitChanged () {
      if (!instances.length) {
        document.documentElement.classList.remove('panel-open')
      } else {
        document.documentElement.classList.add('panel-open')
      }
    }
  },
  mounted () {
    EventBus.$on("panel-open", this.openPanel)
    EventBus.$on("panel-close", this.closePanel)
    EventBus.$on("panel-changed", this.emitChanged)
  }
}
</script>

<style lang="scss">

html.panel-open {
  overflow-y: hidden;
}


.panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  transition: transform 250ms ease-out;
  transform: translateX(100%);
  will-change: transform;
  background-color: white;

  &.active {
    transform: translateX(0);
    z-index: 1000;
  }

  .panel-content {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .panel-close {
    position: absolute;
    z-index: 1001;
    top: 14px;
    right: 34px;
    background-color: transparent;
    border: none;
  }

}

#tetetet {
  right: 80px;

  g {
    stroke: red;
  }
}


</style>
