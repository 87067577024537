<template>
  <component
      :class="[
        renderClass,
        isBlock,
        imageReady ? 'dynamic-image-loaded' : 'dynamic-image-loading'
        ]"
      :is="elementTag"
      class="dynamic-image">
    <img
        ref="image"
        v-bind="$attrs"
        :data-src="url"
        :height="dimensions.height"
        :src="imageReady ? url: blank"
        :width="dimensions.width"
        :alt="alt"
        class="dynamic-image-image"/>
  </component>
</template>
<script>
export default {
  name: 'AsyncImage',
  data () {
    return {
      preloaded: false,
      imageReady: false,
      img: null,
      testTriggerComputed: false,
      dimensions: {},
      loading: false,
      renderNow: false,
      tmp: 'nop',
      loadPercentage: 0
    }
  },
  refs: ['image'],
  computed: {
    blank () {
      return '/img/px.gif'
    },
    renderClass () {
      return (this.forceRenderNow || this.renderNow) ? 'forced' : ''
    },
    isBlock () {
      return this.block ? 'dynamic-image_block' : ''
    },
    elementTag () {
      if (this.containerTag) {
        return this.containerTag
      } else {
        return this.block ? 'div' : 'span'
      }
    }
  },
  methods: {
    getDimensions () {
      let img = this.$refs.image
      let width
      let height

      if (!img) {
        return false
      } else if (this.imageReady) {
        width = this.w100 ? this.$el.offsetWidth : img.naturalWidth
        height = width * (img.naturalHeight / img.naturalWidth)
      } else {
        width = 50
        height = 50
      }
      this.dimensions = {
        width,
        height,
      }
    },
    preloadImage () {
      if (this.preloaded) {
        return false
      }
      this.preloaded = true
      this.img = new Image()
      this.img.addEventListener('load', () => {
        this.imageReady = true
      })
      this.img.addEventListener('error', () => {
        this.imageReady = false
      })
      this.img.src = this.url
      if (this.img.loaded) {
        this.imageReady = true
      }
    },
    preloadImg () {
      let xhr = new XMLHttpRequest()
      xhr.responseType = 'arraybuffer'
      xhr.open('GET', this.url, true)
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
      xhr.onload = function (e) {
        this.imageReady = true
      }
      xhr.onprogress = function (e) {
        this.loadPercentage = parseInt((e.loaded / e.total) * 100, 10)
      }
      xhr.onloadstart = () => {
        this.loadPercentage = 0
      }
      xhr.onloadend = () => {
        this.loadPercentage = 100
      }
      xhr.send()
    }
  },
  watch: {
    imageReady (value) {
      if (value) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.getDimensions()
            delete this.img
          }, 200)
        })
      }
    }
  },
  mounted () {
    if (this.observeIntersection) {
      try {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0.2) {
              this.preloadImage()
              this.observer.unobserve(this.$el)
              delete this.observer
            }
          })
        }, this.observerOptions);
        this.observer.observe(this.$el)
      } catch (e) {
        this.preloadImage()
      }
    } else {
      this.preloadImage()
    }
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    w100: {
      type: Boolean,
      default: true
    },
    h100: {
      type: Boolean,
      default: false
    },
    noPreload: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    alt: {
      type: String,
      default: ''
    },
    containerTag: {
      type: String,
      default: ''
    },
    observerOptions: {
      type: Object,
      default () {
        return {
          rootMargin: '0px',
          threshold: [0, 1.0]
        }
      }
    },
    observeIntersection: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss">
.dynamic-image {
  opacity: 1;
  /*display: flex;
  flex-direction: column;*/
}

.dynamic-image-image {
  transition: opacity 100ms 200ms, height 100ms 0ms ease-in;
  opacity: 1;
  width: 100%;
}

.forced {
  .dynamic-image-image {
    transition: 0ms;
    opacity: 1;
  }
}

.dynamic-image-loading:not(.forced) {
  background: url('/img/spinner.gif') no-repeat center center / 50px 50px;
  height: 50px;
  width: 100%;
  opacity: 0.2;

  .dynamic-image-image {
    height: 100%;
    width: 100%;
    opacity: 0;
  }
}
</style>
