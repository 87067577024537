import '@/assets/styles/DCR.scss';
import Logo from '@/components/Logo.vue';
import Media from '@/components/Media.vue';
import EventBus from "@/helpers/EventBus";
// import api from "@/helpers/api";
import {ButtonGroupPlugin, ButtonPlugin, ModalPlugin, PopoverPlugin} from 'bootstrap-vue';
import Vue from 'vue';
import VueMeta from 'vue-meta'
import {ReactiveRefs} from 'vue-reactive-refs'
import VueScrollTo from 'vue-scrollto';
// @ts-ignore
import vSelect from 'vue-select'
import "vue-select/src/scss/vue-select.scss";
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(VueMeta)
Vue.use(ReactiveRefs)
Vue.use(ModalPlugin);
Vue.use(PopoverPlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(VueScrollTo);

Vue.component('logo', Logo);
Vue.component('media', Media);
Vue.component('v-select', vSelect)

Vue.config.productionTip = true;

let firstRouteResolution = true
router.beforeEach(async (to, from, next) => {
  if (firstRouteResolution) {
    let token = sessionStorage.getItem('token')
    if (token) {
      await store.dispatch('SET_USER', JSON.parse(token))
    }
    firstRouteResolution = false
  }
  const usr = store.state.user || {}

  if (to.name !== 'connect' && to.meta.secure && !store.state.secured) {
    next(`/connexion?to=${to.path}`)
  } else if (to.name === 'admin_index' && usr.role !== "ADMIN") {
    next(`/administration/talent/${usr.screenName}`)
  } else {
    next()
  }

})
// @ts-ignore
window.DCR = new Vue({
  router,
  store,
  render: (h) => h(App),
  async mounted() {
    await Promise.all([
      await store.dispatch('INIT_DEVICE'),
      await store.dispatch('GET_CONFIG'),
      await store.dispatch('GET_TALENTS'),
    ]).then(async () => {
      document.documentElement.classList.remove('loading');
      document.documentElement.classList.add('loaded');
      EventBus.$on('user-unconnect', () => {
        this.$router.push('/')
      })
      setTimeout(async () => {
        await store.commit('SET_APP_LOADED')
        document.dispatchEvent(new Event('x-app-rendered'))
      }, 300)
    })
  }
}).$mount('#app');
