<template>
  <div :id="id"
       :class="[
        maxPage > 0 ? 'active' : 'inactive',
        items.length < 4 ? 'short' : '',
     ]"
       class="pagination-box">
    <ul class="pagination-list">
      <li class="pagination-item pagination-previous">
        <a :class="limitReached === 'min' ? 'inactive' : 'active'"
           class="pagination-button"
           href="#prev"
           @click.prevent="previousPage()">
          <template v-if="!device.isMobile">Précédent</template>
          <template v-else>&lt;</template>
        </a></li>
      <li class="pagination-item pagination-first">
        <a :class="limitReached === 'min' ? 'current' : ''"
           class="pagination-button"
           href="#first"
           @click.prevent="firstPage(0)">1</a>
      </li>
    </ul>
    <div class="pagination-list pagination-list-dynamics">
      <ul ref="dynamics" :class="translateX === 0 ? 'no-fx' : ''" :style="`transform: translateX(${translateX}px);`">
        <li v-for="(item, idx) of items" class="pagination-item pagination-item-dynamic">
          <a :class="index === (idx+1) ? 'current' : ''"
             :href="`#p-${idx+1}`"
             class="pagination-button"
             @click.prevent="setPage(idx+1)">{{ idx + 2 }}</a></li>
      </ul>
    </div>
    <ul class="pagination-list">
      <li class="pagination-item pagination-last">
        <a :class="index === items.length + 1 ? 'current' : ''"
           class="pagination-button"
           href="#last"
           @click.prevent="lastPage()">{{ maxPage + 1 }}</a>
      </li>
      <li class="pagination-item pagination-next">
        <a :class="limitReached === 'max' ? 'inactive' : 'active'"
           class="pagination-button"
           href="#prev"
           @click.prevent="nextPage()">
          <template v-if="!device.isMobile">Suivant</template>
          <template v-else>&gt;</template>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import debounce from 'lodash.debounce'
import {mapState} from 'vuex'

export default {
  name: 'pagination',
  data () {
    return {
      translateX: 0,
      endReached: false
    }
  },
  methods: {
    setPage: debounce(function (index) {
      let pageIndex = this.secureIndex(index)
      this.$emit('paginate',
          pageIndex,
          pageIndex * this.paginationLength,
          ((pageIndex * this.paginationLength) + this.paginationLength)
      )
      setTimeout(() => {
        this.refreshView()
      }, 300)
    }, 60),

    refreshView () {
      try {
        let elm = this.$refs.dynamics
        let W = elm.scrollWidth
        let w = elm.parentNode.offsetWidth
        let diff = W - w
        let halfw = w / 2
        let item = elm.querySelector('li:nth-of-type(2)')
        let itemWidth
        if (item) {
          itemWidth = item.offsetLeft
        } else {
          item = elm.querySelector('li:nth-of-type(1)')
          itemWidth = item.offsetLeft
        }

        let delta = -(itemWidth * (this.index)) + halfw - (itemWidth / 2)
        if (delta > 0) {
          delta = 0
        } else if (delta < (-diff)) {
          delta = -diff
        }
        this.$set(this, 'endReached', delta === (w - W))
        this.positionList(delta - delta % itemWidth)
      } catch (err) {
        console.warn(err)
      }
    },
    positionList (qty) {
      this.$set(this, 'translateX', qty)
    },
    secureIndex (index) {
      if (index <= 0) {
        return 0
      } else if (index > this.maxPage) {
        return this.maxPage
      }
      return index
    },
    firstPage () {
      this.setPage(0)
    },
    lastPage () {
      this.setPage(this.maxPage)
    },
    previousPage () {
      this.setPage(this.index - 1)
    },
    nextPage () {
      this.setPage(this.index + 1)
    },
  },
  computed: {
    ...mapState(["device"]),
    maxPage () {
      return Math.ceil(this.data.length / this.paginationLength) - 1
    },
    limitReached () {
      if (this.index === 0) {
        return 'min'
      } else if (this.index === this.maxPage) {
        return 'max'
      } else {
        return ''
      }
    },
    items () {
      if (this.maxPage < 1) {
        return []
      }
      return new Array(this.maxPage - 1)
    },
    deltaStyle () {
      return {transform: `translateX(${this.translateX}px);`}
    },
  },
  watch: {
    data (d) {
      this.$nextTick(() => {
        this.positionList(0)
        this.$nextTick(() => {
          this.setPage(0)
        })
      })
    },
    index: {
      immediate: true,
      handler (value) {
        this.$nextTick(() => {
          this.setPage(value)
          this.refreshView()
        })
      },
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    paginationLength: {
      type: Number,
      default: 20,
    },
    index: {
      type: Number,
      default: 0,
    },
    maxItem: {
      type: Number,
      default: 5,
      validator (value) {
        let odd = value % 2 !== 0
        return odd
      },
    },
    syncWith: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  mounted () {
    this.setPage(this.index)
    window.addEventListener('resize', () => {
      this.refreshView()
    })
  },
}
</script>
<style lang="scss">
@import "~@/assets/styles/_lists.scss";

.pagination-list {
  @extend .inline-list;
  @extend .flex-center;
  margin: 40px 0;
  user-select: none;
}

.pagination-item {
  min-width: 40px;
  max-width: 40px;
}

.pagination-length {
  margin-right: 0;
  margin-left: 5px;

  a {
    font-weight: 500;
    padding: 15px 5px;
    font-size: 15px;
    white-space: nowrap;

    &.active {
      opacity: 0;
    }
  }
}

.pagination-button {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 5px;
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  display: inline-block;

  &:hover {
    font-weight: 600;
    text-decoration: none;
  }

  &.inactive {
    pointer-events: none;
    //color: $pale-grey
  }
}

.pagination-list {
  position: relative;
  margin: 0;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-align: center;

  ul {
    transition: 250ms;
    max-width: 400px;
  }

  li {
    display: inline-block;
    margin: 0;

  }

  .pagination-first {
    margin-right: 0;
    max-width: 80px;

    &:after {
      @extend .injectDot;
      text-align: right;
    }

    &.current {
      &:before {
        @extend .injectCurrent;
      }
    }
  }

  .pagination-last {
    margin-left: 0;

    &:before {
      @extend .injectDot;
      text-align: left;
    }

    &.current {
      &:after {
        @extend .injectCurrent;
      }
    }
  }

  .pagination-previous {
    margin-right: 60px;
  }

  .pagination-next {
    margin-left: 60px;
  }

  .pagination-previous, .pagination-next {
    min-width: 0;
    max-width: none;

    .pagination-button {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

}

.pagination-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  margin: 70px 0 40px;
  overflow: hidden;

  &.active {
    transition: 250ms;
    opacity: 1;
    //height: 42px;
  }

  &.inactive {
    transition: 0ms;
    opacity: 0;
  }
}

.pagination-box.short, .is-mobile.is-portrait .pagination-box {
  .pagination-list {
    .pagination-first:after {
      @extend .injectNoDot
    }

    .pagination-last:before {
      @extend .injectNoDot
    }
  }
}

.injectDot {
  content: '...';
  width: 22px;
  display: inline-block;
}

.injectNoDot {
  content: '';
  width: auto;
}

.injectCurrent {
  content: '';
  position: absolute;
  height: 3px;
  left: -2px;
  right: -2px;
  bottom: 0px;
  background-color: black;
}

.pagination-button.current {
  position: relative;
  font-weight: 700;


  &:after {
    @extend .injectCurrent;
  }
}

.is-mobile.is-landscape {
  .pagination-previous, .pagination-next {
    font-family: monospace;
    margin-right: 0;
    margin-left: 0;
  }

  .pagination-item {
    min-width: 30px;
    max-width: 30px;
  }

  .pagination-button {
    padding: 5px 0;
  }
}

.pagination-list-dynamics ul.no-fx {
  transition: 0ms;
}
</style>
