<template>
  <div :class="visible ? 'visible' : 'not-visible'" class="favoritesTools" v-if="visible">
    <span id="favorite"
          :class="isFavorite ? 'active' : ''"
          class="tools-button"
          data-label="Favori"
          role="button"
          tabindex="0"
          @click.prevent.stop="toggleFavoriteState">
      <component :is="favori" height="20" width="20"></component>
      <span v-if="!device.isDesktop">Favori</span>
    </span>
    <span id="email" :class="isPopoverOpen ? 'active' : ''" class="tools-button" data-label="+ d'infos" role="button"
          tabindex="0">
      <template v-if="device.isDesktop">
        <component :is="email" height="20" width="20"></component>
      </template>
      <template v-else>
        <component :is="plus" height="14" width="14"></component>
        <span>d'infos</span>
      </template>
    </span>

    <b-popover
        id="sendInfoContentPopover"
        ref="popover"
        :container="$el"
        :placement="device.isMobile ? 'top' : device.isTablet ? 'top' : 'left'"
        :show.sync="showPopover"
        target="email"
        triggers="click"
        @hide="isPopoverOpen=false"
        @show="isPopoverOpen=true"
    >
      <div id="sendInfoContent">
        <form @submit.prevent="sendUserInfo" @blur.stop>
          <fieldset>
            <legend>Recevez plus d’infos</legend>
            <div class="formLine">
              <label for="userEmail">Email</label>
              <input id="userEmail" v-model="userEmail" required type="email"/>
            </div>
            <div class="formLine">
              <b-button type="submit">Envoyer <loader :width="20" v-if="isSendingEmail" :inline="true"></loader></b-button>
            </div>

            <div v-if="isSendingEmail" class="formMsg">&nbsp;</div>
            <div v-else-if="isSuccess" class="formMsg success">Email envoyé. Merci !<br><code>Pensez à vérifier vos spams</code></div>
            <div v-else-if="isError" class="formMsg error">Email non envoyé. Merci de réessayer !<br><code>{{errorMessage}}</code></div>
            <div v-else class="formMsg">&nbsp;</div>
          </fieldset>
        </form>
      </div>
    </b-popover>
  </div>
</template>
<script>
import Loader from "@/components/Loader";
import {mapState} from 'vuex'

export default {
  name: 'TalentTools',
  components: {Loader},
  data () {
    return {
      showPopover: false,
      isSuccess: false,
      isError: false,
      isPopoverOpen: false,
      isSendingEmail: false,
      errorMessage: ""
    }
  },
  methods: {
    toggleFavoriteState () {
      if (this.isFavorite) {
        this.$store.commit('REMOVE_FROM_FAVORITES', this.currentTalent)
      } else {
        this.$store.commit('ADD_TO_FAVORITES', this.currentTalent)
      }
    },
    async sendUserInfo () {
      this.isSendingEmail = true
      await this.$store.dispatch('SEND_TALENT_INFO', this.userEmail)
          .then((res) => {
            if (res.status) {
              this.isSuccess = true
              this.isError = false
              this.errorMessage = ""
              setTimeout(() => {
                this.resetState()
              }, 3000)
            } else {
              this.isSuccess = false
              this.isError = true
              this.errorMessage = res.message
            }
          })
          .finally(() => {
            this.isSendingEmail = false
          })
    },

    resetState () {
      this.showPopover = false
      this.isSuccess = false
      this.isError = false
      this.errorMessage = ""
    }
  },
  computed: {
    ...mapState(['favoritesList', 'currentTalent', 'device']),
    userEmail: {
      get () {
        return this.$store.state.userEmail
      },
      set (email) {
        this.$store.commit('SET_USER_EMAIL', email)
      }
    },
    isFavorite () {
      if (this.currentTalent) {
        for (let talent of this.favoritesList) {
          if (talent.uuid === this.currentTalent.uuid) {
            return true
          }
        }
      }
      return false
    },
    favori () {
      return require('@/assets/svg/favoris.svg')
    },
    email () {
      return require('@/assets/svg/email.svg')
    },
    plus () {
      return require('@/assets/svg/plus.svg')
    },
  },
  watch: {
    favoritesList () {
      if (this.favoritesList.length) {
        localStorage.setItem('favoriteTalents', JSON.stringify(this.favoritesList))
      } else {
        localStorage.removeItem('favoriteTalents')
      }
    },
    showPopover (show) {
      if (!show) {
        this.isSuccess = false
        this.isError = false
      }
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss">
.favoritesTools {
  position: absolute;
  top: calc(100% + 50px);
  right: 20px;
  flex-direction: column;
  transform: translateX(0);
  width: auto;
  transition: transform 250ms 400ms;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -60px;
  //animation: fadeIn 250ms 250ms reverse;
  transition: 250ms;

  .tools-button {
    margin-left: 0;
    margin-bottom: 40px;
  }

  &.visible {
    //transform: translateY(-30px) translateX(-20px);
    opacity: 1;
  }

  &.not-visible {
    //transform: translateY(-30px) translateX(calc(100% + 20px));
    display: none;
    opacity: 0;
  }
}

.is-mobile .favoritesTools,
.is-tablet .favoritesTools {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  flex-direction: row;
  z-index: 10000;
  background: white;
  margin: 0;
  animation: none;
  display: flex;
  justify-content: space-around;

  &.visible {
    transform: none;
    opacity: 1;
  }

  &.not-visible {
    transform: none;
    opacity: 0;
  }

  .tools-button {
    max-width: none;
    max-height: none;
    width: 45%;
    height: 35px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;

    svg {
      margin: 0 20px 0 0;
    }

    span {
      text-align: center;
    }
  }

  #email,
  #favorite {
    border: 1px solid black;
    background: #fff;
    color: black;
  }

  #email path {
    stroke-width: 10px;
  }

  #favorite.active {
    background: #91D9D5F2;
    color: black;
  }

  #email.active {
    background: $pale-red;
    color: black;
  }

}

.is-tablet .favoritesTools {
  .tools-button {
    margin: 30px 0;
    padding: 30px 20px;
    font-size: 24px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.is-large:not(.is-tablet) .favoritesTools {
  transition: 0ms;
  transform-origin: center top;
  transform: scale(0.9);
  top: calc(100% + 33px);
}


.tools-button {
  border-radius: 60px;
  background-color: #fff;
  margin-left: 30px;
  cursor: pointer;
  display: flex;
  height: 60px;
  width: 60px;
  border: 1px solid black;
  position: relative;

  &:after {
    content: attr(data-label);
    position: absolute;
    bottom: -1.7em;
    line-height: 1;
    left: -10px;
    right: -10px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  svg {
    margin: auto;
  }

  &:focus {
    outline: none;
  }
}

.is-tablet, .is-mobile {
  .tools-button:after {
    display: none;
  }
}

#email.active, #email:hover {
  background-color: $pale-red;
  border-color: $pale-red;
}

#favorite.active, #favorite:hover {
  background-color: $dcr-blue;
  border-color: $dcr-blue;
}


#sendInfoContentPopover {
  background-color: $pale-red;
  border-color: transparent;
  width: 390px;
  max-width: 390px;
  padding: 40px 50px;
  animation: slideLeft;

  .btn {
    position: relative;
  }

  .loader {
    position: absolute;
    right: 10px;
  }

  .arrow:before {
    border-left-color: $pale-red !important;
  }

  .arrow:after {
    border-left-color: $pale-red !important;
  }

  legend {
    font-size: 35px;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 1em;
  }

  label {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
  }

  input, .btn {
    display: block;
    background: white;
    border: 2px solid $black;
    padding: 11px;
    font-size: 14px;
    width: 100%;
    font-weight: 400;
    letter-spacing: .04em;
  }

  input {
    font-size: 16px;
  }

  .btn {
    background-color: black;
    color: white;
    text-transform: uppercase;
    margin-top: 10px;
    border-radius: 0;
  }

  .success {
    color: $green;
  }

  .error {
    color: $red;
  }

  .formMsg {
    padding: 5px 0;
    text-align: center;
    line-height: 22px;

    code {
      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }
      color: black;
      display: block;
      white-space: nowrap;
      font-size: 12px;
    }
  }
}

.is-mobile, .is-tablet {
  #sendInfoContentPopover {
    width: 100%;
  }
}
</style>
