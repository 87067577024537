<template>
    <span id="header-mobile-button" :class="classname" @click="togglePanel">
      <span id="header-mobile-button-top"></span>
      <span id="header-mobile-button-middle"></span>
      <span id="header-mobile-button-bottom"></span>
    </span>
</template>
<script>
import EventBus from '@/helpers/EventBus'

export default {
  name: "panelButton",
  data () {
    return {
      state: false,
      currentId: null
    }
  },
  methods: {
    setPanel (status) {
      this.state = status
      if (this.state) {
        EventBus.$emit("panel-open", false)
        document.documentElement.classList.add('panel-open')
      } else {
        EventBus.$emit("panel-close", false)
        document.documentElement.classList.remove('panel-open')
      }
    },
    togglePanel () {
      this.setPanel(!this.state)
    }
  },
  computed: {
    classname () {
      return this.state ? 'active' : 'inactive'
    }
  },
  mounted () {
    EventBus.$on('panel-open', (id) => {
      if (id) {
        this.setPanel(true)
        this.currentId = id
      }
    })
    EventBus.$on('panel-close', (id) => {
      if (id) {
        this.setPanel(false)
        this.currentId = null
      }
    })
  }
}
</script>

<style lang="scss">

</style>
