<template>
  <div class="home">
    <template v-if="device.isDesktop">
      <section>
        <h1 class="sr-only">Chercher un freelance</h1>
      </section>
      <div id="searchBox" :class="[device.is, classname]">

        <!-- filtre profilType -->
        <ul class="inline-list filter-list flex-center" :class="device.isMobile ? 'inline-list-stack' : ''">
          <li v-for="(value, key, index) in config.profilType" class="filter-list-item">
            <a
                :class="filters.profilType == key ? 'active' : 'inactive'"
                :href="`#${key}`"
                class="filter-list-button"
                @click.prevent="filterBy('profilType', key)">{{ value }}</a>
          </li>
        </ul>
        <!-- search -->
        <div class="talents-filter" :class="tags.length ? 'active' : 'inactive'">
          <v-select
              ref="select"
              id="searchButton"
              v-model="tags"
              :filterBy="filterTag"
              :options="availableTags"
              :searchable="true"
              @search="listenSearch"
              :noDrop="!showDropList"
              multiple
              key="desktop"
              appendToBody="#searchTagsList"
              placeholder="Rechercher un profil par tags">
            <template #no-options="{ search, searching, loading }">
              <template v-if="searching">Pas de filtre supplémentaire</template>
            </template>
            <template #selected-option-container="{ option, deselect, multiple, disabled }">
              <div class="vs__selected">
                {{ option.label }}
                <component
                    class="clickable"
                    :is="crossButton"
                    @click.prevent.stop="deselect(option)"
                    width="10"
                    height="10"
                />
              </div>

            </template>
          </v-select>
          <template v-if="filters.prenom || tags.length || filters.profilType">
            <span v-if="filteredTalents.length" id="filteredTalentLength">{{ filteredTalents.length }} talent<template
                v-if="filteredTalents.length>1">s</template>
            </span>
          </template>
          <component
              v-if="tags.length || (filters.prenom && filters.prenom.length)"
              :is="crossButton"
              class="talents-filter-image clickable"
              @click="clearField"
              width="32"
              height="32"
          />
          <component
              v-else
              width="32" height="32"
              :is="searchButton"
              class="talents-filter-image"
          />

        </div>
        <div id="searchTagsList"></div>
      </div>
    </template>
    <template v-else>
      <h1 id="searchBoxPanelButton">
        <b-btn size="lg" @click="openPanel('searchBoxPanel')">Chercher un freelance</b-btn>
      </h1>
      <panel
          id="searchBoxPanel">
        <logo></logo>
        <p class="searchBoxPanelBaseline">Chercher un freelance</p>
        <div id="searchBox" :class="[device.is, classname]">

          <!-- filtre profilType -->
          <ul class="inline-list filter-list flex-center">
            <li v-for="(value, key, index) in config.profilType" class="filter-list-item">
              <a
                  :class="filters.profilType == key ? 'active' : 'inactive'"
                  :href="`#${key}`"
                  class="filter-list-button"
                  @click.prevent="filterBy('profilType', key)">{{ value }}</a>
            </li>
          </ul>
          <!-- search -->
          <div class="talents-filter" :class="tags.length ? 'active' : 'inactive'">
            <v-select
                ref="select"
                id="searchButton"
                v-model="tags"
                :filterBy="filterTag"
                :options="availableTags"
                :searchable="true"
                @search="listenSearch"
                :noDrop="!showDropList"
                multiple
                key="desktop"
                appendToBody="#searchTagsList"
                placeholder="Rechercher un profil par tags">
              <template #no-options="{ search, searching, loading }">
                <template v-if="searching">Pas de filtre supplémentaire</template>
              </template>
              <template #selected-option-container="{ option, deselect, multiple, disabled }">
                <div class="vs__selected">
                  {{ option.label }}
                  <component
                      class="clickable"
                      :is="crossButton"
                      @click.prevent.stop="deselect(option)"
                      width="10"
                      height="10"
                  />
                </div>

              </template>
            </v-select>
            <template v-if="filters.prenom || tags.length || filters.profilType">
                <span v-if="filteredTalents.length" id="filteredTalentLength">{{ filteredTalents.length }} talent<template
                    v-if="filteredTalents.length>1">s</template>
                </span>
            </template>
            <component
                v-if="tags.length || (filters.prenom && filters.prenom.length)"
                :is="crossButton"
                class="talents-filter-image clickable"
                @click="clearField"
                width="32"
                height="32"
            />
            <component
                v-else
                width="32" height="32"
                :is="searchButton"
                class="talents-filter-image"
            />

          </div>
          <div id="mobileSearchValidate">
            <b-btn size="lg" @click="onValidate">Valider</b-btn>
          </div>
          <div id="searchTagsList"></div>
        </div>
      </panel>
    </template>
    <pagination
        id="talentPagination"
        class="pagination-top"
        :data="filteredTalents"
        :index="pagination"
        :max-item="5"
        :pagination-length="breakpoint.maxTalents"
        @paginate="setPagination"></pagination>
    <keep-alive>
      <talents v-if="true" :talents="paginatedTalents"></talents>
    </keep-alive>
    <pagination
        id="talentPagination1"
        :data="filteredTalents"
        :index="pagination"
        :max-item="5"
        :pagination-length="breakpoint.maxTalents"
        @paginate="setBottomPagination"></pagination>
    <logo class="logo-bottom"></logo>
  </div>
</template>
<script>
import Pagination from '@/components/pagination'
import Panel from "@/components/Panel";
import Talents from '@/components/Talents'
import EventBus from '@/helpers/EventBus'
import removeAccent from '@/helpers/removeAccent'
import Vue from 'vue'
import {mapState} from 'vuex'

export default Vue.extend({
  name: 'home',
  metaInfo: {
    title: "Trouver votre talent !",
    titleTemplate: '%s - DRiMSCREATiVE ',
    meta: [
      {
        name: "description",
        content: "Agents de Freelances, Agent de Talents"
      }
    ]
  },
  components: {Panel, Talents, Pagination},
  refs: ['select'],
  data () {
    return {
      filters: {
        profilType: null,
        prenom: null
      },
      isFilterOpen: false,
      pagination: 0,
      paginatedTalents: [],
      tags: [],
      tagDisabled: false,
      q: '',
      searchComponent: null,
      searchString: '',
      lastSearchString: ''

    }
  },
  computed: {
    ...mapState(['talents', 'talentsByType', 'config', 'breakpoint', 'device', 'appLoaded']),
    availableTags () {
      let tags = []
      if (this.filteredTalents.length < 2) {
        return tags
      }
      let i = 0
      for (let talent of this.filteredTalents) {

        if (talent.tags && talent.tags.length) {
          ++i;
        }

        if (!tags.includes(`prenom_${talent.prenom}`)) {
          tags.push(`prenom_${talent.prenom}`)
        }

        if (['DEV', 'GES', 'CREA'].includes(talent.profilType) && talent.jobs) {
          for (let job of talent.jobs) {
            if (!tags.includes(`jobs_${job}`)) {
              tags.push(`jobs_${job}`)
            }
          }
          for (let tag of talent.tags) {
            if (!tags.includes(`tags_${tag}`)) {
              tags.push(`tags_${tag}`)
            }

          }
        }
      }

      let availables = this.config.searchTag
          .filter((item) => {
            // keep only filtered tags
            return tags.includes(item.code)
          })
          .filter((item) => {
            // do not show already selected tags
            return true
          })
          .sort((a, b) => {
            return a.label > b.label ? 1 : -1
          })

      return availables
    },

    filteredTalents () {
      let talents = this.talentsFilteredByPrenom
      for (let tag of this.tags) {
        let sp = tag.code.split('_')
        talents = talents.filter((item) => {
          return item[sp[0]].includes(sp[1])
        })
      }

      talents.forEach((talent, index) => {
        talent.filterIndex = index
      })
      this.$store.commit('SET_FILTERED_TALENTS', talents)
      return talents
    },
    talentsFilteredByPrenom () {
      let talents = this.talentsFilteredByType
      if (this.filters.prenom) {
        talents = talents.filter((talent) => {
          let normalizedPrenom = removeAccent(talent.displayName).toLowerCase()
          return normalizedPrenom.indexOf(this.filters.prenom.toLowerCase()) > -1
        })
      }
      return talents
    },
    talentsFilteredByType () {
      let talents = this.talents
      if (this.filters.profilType) {
        talents = this.talentsByType[this.filters.profilType]
      }
      talents = talents.filter((talent) => {
        return talent.online === '1'
      })
      return talents
    },

    showDropList () {
      let show = true
      if (this.searchComponent) {
        if (!this.searchComponent.filteredOptions.length) {
          show = false
        } else {
          if (this.tags.length === 0 && this.searchString.length === 0) {
            show = false
          }
          this.lastSearchString = this.searchString
        }
        return show
      } else {
        return false
      }
    },

    searchButton () {
      return require('@/assets/svg/loupe.svg')
    },
    crossButton () {
      return require('@/assets/svg/croix.svg')
    },
    shouldShowAsPanel () {
      return false
    },
    classname () {
      return [
        this.shouldShowAsPanel ? 'panel' : '',
        this.isFilterOpen ? 'active' : ''
      ]
    },
    tagsHashMap () {
      return this.tags.reduce((acc, item) => {
        acc.push(item.code)
        return acc
      }, [])
    }
  },
  methods: {
    normalizeString (str) {
      return str.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
    },
    listenSearch (search, m) {
      this.filterBy('prenom', null)
      this.searchString = removeAccent(search).toLowerCase()
      this.$nextTick(() => {
        // if no tags is find with the searchString
        if (!this.searchComponent.filteredOptions.length) {
          this.filterBy('prenom', this.searchString)
        }
      })
    },
    filterTag (option, label, search) {
      let Label = removeAccent(label || '').toLowerCase()
      let Search = removeAccent(search).toLowerCase()
      if (!Search.length) {
        return false
      }

      let sp = Label.split(' ')

      let SearchExp = new RegExp(`^${removeAccent(search)}`, 'i')

      return sp.some((word) => {
        return SearchExp.test(word)
      })
    },

    filterBy (type, value) {
      if (this.filters[type] === value) {
        this.$set(this.filters, type, null)
      } else if (value) {
        this.$set(this.filters, type, removeAccent(value))
      } else {
        this.$set(this.filters, type, value)
      }
    },

    setPagination (pageIndex, startIndex, endIndex) {
      this.paginatedTalents = this.filteredTalents.slice(startIndex, endIndex)
      this.pagination = pageIndex
    },

    setBottomPagination (pageIndex, startIndex, endIndex) {
      this.setPagination(pageIndex, startIndex, endIndex)
    },

    waitForSelect () {
      if (this.$refs.select) {
        let element = this.$refs.select.searchEl
        this.searchComponent = this.$refs.select
        this.searchInput = element
        element.addEventListener('xxxinput', () => {
          this.$nextTick(() => {
            if (!this.searchComponent.filteredOptions.length) {
              this.filterBy('prenom', this.searchString)
            } else {
              this.filterBy('prenom', null)
            }

          })
        })
      } else {
        setTimeout(() => {
          this.waitForSelect()
        }, 100)
      }

    },
    giveBackFocus () {
      this.$refs.select.searchEl.focus()
    },
    clearField () {
      this.filterBy('prenom', null)
      this.searchComponent.search = ''
      this.tags = []
    },
    openPanel (id) {
      EventBus.$emit("panel-open", id)
    },
    onValidate () {
      EventBus.$emit("panel-close", "searchBoxPanel")
    }
  },
  watch: {
    tags (value) {
      this.giveBackFocus()
    },
    pagination () {
      Vue.prototype.$scrollTo('#talentPagination', {
        offset: -50,
        duration: 300
      })
    }
  },
  mounted () {
    this.waitForSelect()
  }
})
</script>
<style lang="scss">
#searchButton {
  flex: 1;

  .vs__dropdown-toggle {
    border: none;
  }

  .vs__actions {
    display: none;
  }

  .vs__selected {
    font-size: 14px;
    font-weight: 600;
    background-color: white;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 400px;
    color: black;
    white-space: nowrap;
    margin: 0px 10px 10px 0;

    svg {
      margin-left: 10px;
    }
  }

  .vs__dropdown-toggle {
    padding: 0;
  }

  .vs__dropdown-menu {
    width: 700px !important;
    margin-top: 40px;
    border-radius: 0;
    // border: 1px solid rgba(0,0,0,.2) !important;
    //border: 2px solid #FAF7C0 !important;
    border-top: none !important;

    ul {
      padding: 0;
    }

    li {
      background-color: white;
      border: 1px solid black;
      padding: 10px 20px;
      border-radius: 100px;
      font-size: 14px;
    }
  }


  .vs__deselect {
    margin: 0 0 0 .5em;

    path {
      fill: black;
    }
  }

  .vs__selected-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    margin-left: 25px;


    input {
      border: none;
      padding: 2px;
      margin-right: 25px;
      color: $black;
      font-size: 16px;
      min-width: 3ex;
    }
  }
}

#searchTagsList {
  padding: 40px 0 0;

  .vs__dropdown-menu {
    position: static;
    border-radius: 0;
    display: flex;
    width: 100% !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-height: none;

    li {
      background-color: white;
      border: 1px solid black;
      padding: 10px 20px;
      border-radius: 100px;
      font-size: 14px;

      a {
        background: blue;
      }
    }
  }
}

.is-mobile {
  #searchButton {
    .vs__selected-options {
      .vs__selected {
        font-size: 18px;
        padding: 5px 10px;
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }
  }
}

.is-tablet {
  #searchButton {
    .vs__selected-options {
      .vs__selected {
        font-size: 18px;
        padding: 5px 10px;
        margin-right: 10px;
        margin-bottom: 4px;
      }
    }
  }
}

#searchBox.panel {
  padding-top: 60px;

  .filter-list-item {
    margin: 0 0 60px;
  }
}

.talents-filter {
  background-color: $pale-yellow;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 700px;
  max-width: fit-content;
  margin: 0 auto 0px;
  padding: 18px 0;

  &.active {
    #searchButton .vs__selected-options {
      margin-bottom: -10px;
    }

    #searchButton .vs__selected-options input {
      margin-top: -10px;
    }
  }

  #search {
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    outline: none;
    border-left: 1px solid $black;
    width: 100%;
    padding: 2px 25px;
    margin-right: 25px;
    color: $black;
    font-size: 16px;
  }

  #search::placeholder {
    color: $black;
    font-size: 16px;
  }

  .talents-filter-image {
    margin-right: 25px;
  }
}

.is-mobile,
.is-tablet {
  .talents-filter {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
  }
}

.filter-list {
  .filter-list-item {
    margin: 0 50px 50px;
    white-space: nowrap;
  }

  .filter-list-button {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 30px;
    -webkit-appearance: none;
    text-decoration: none;
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      height: 31px;
      z-index: -1;
      background-image: url(~@/assets/images/background/filterSelected.jpg);
      background-repeat: no-repeat;
      background-size: 100% 31px;
      background-clip: padding-box;
      background-position: 10px center;
      transition: 50ms $easeOutExpo;
      transform: translateY(-50%);
      transform-origin: left center;
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }

    &.active:after {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      transition: 150ms ease-in-out;
    }
  }
}

.is-mobile .filter-list {
  text-align: center;
  flex-direction: column;

  .filter-list-item {
    margin: 0 0 15px;
  }
}

.home {
  user-select: none;

  .logo-bottom {
    margin-top: 120px;
  }
}

#filteredTalentLength {
  font-size: 15px;
  font-weight: 400;
  margin-right: 20px;
}

.is-tablet,
.is-mobile {
  .searchBoxPanelBottom {
    display: block;
  }

  #filteredTalentLength {
    margin: 20px 20px 20px 0;
    display: block;
  }
}

#clearFieldButton {
  cursor: pointer;
  margin-right: 1em;

  path {
    stroke-width: 1px;
  }
}

.searchBoxPanelBaseline {
  text-align: center;
  font-size: 48px;
  margin-bottom: 60px;
}

.is-mobile .searchBoxPanelBaseline {
  font-size: 24px;
  margin-bottom: 40px;
}

.searchBoxPanelBottom {
  text-align: center;

  button {
    border: none;
    background-color: black;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    padding: 20px 60px;
  }
}

#searchBoxPanel {
  .logo {
    margin: 53px 40px 20px;
  }
}

#searchBoxPanelButton {
  margin-bottom: 40px;

  .btn {
    background-color: black;
    color: #fff;
    font-size: inherit;
    text-transform: uppercase;
  }
}

.is-mobile, .is-tablet {
  #talentPagination {
    margin-top: 40px;
  }

  #searchBoxPanelButton {
    margin-bottom: 20px;

    .btn {
      font-size: 20px;
    }
  }
}

#mobileSearchValidate {
  margin-top: 40px;
  text-align: center;

  .btn {
    background-color: black;
    color: #fff;
    font-size: 30px;
  }
}
</style>
