import store from "@/store";
import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Maintenance from './views/Maintenance.vue';
import NotFound from './views/NotFound.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        backButtonHided: true
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      meta: {
        backButtonHided: true
      }
    },
    {
      path: '/qui-sommes-nous',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
      meta: {
        logo: 'grey'
      }
    },
    {
      path: '/connexion',
      name: 'connect',
      component: () => import(/* webpackChunkName: "about" */ './views/Connexion.vue'),
      meta: {
        logo: 'grey'
      }
    },
    {
      path: '/mentions-legales',
      name: 'mentions',
      component: () => import(/* webpackChunkName: "about" */ './views/Mentions.vue'),
      meta: {
        logo: 'grey'
      }

    },
    {
      path: '/politique-de-confidentialite',
      name: 'confidential',
      component: () => import(/* webpackChunkName: "about" */ './views/Confidential.vue'),
      meta: {
        logo: 'grey'
      }
    },
    {
      path: '/nos-talents',
      name: 'talents',
      component: () => import(/* webpackChunkName: "seo" */ './views/Talents.vue'),
      meta: {
        logo: 'grey'
      }
    },
    {
      path: '/talent/:name',
      name: 'talent',
      component: () => import(/* webpackChunkName: "talent" */ './views/Talent.vue'),
      meta: {
        backButtonText: "Retour à la liste",
        logo: 'grey'
      }
    },
    {
      path: '/talent/:name/projet/:slug',
      name: 'project',
      component: () => import(/* webpackChunkName: "talent" */ './views/Projet.vue'),
      meta: {
        backButtonText: "Retour au portfolio",
        logo: 'grey'
      }
    },
    {
      path: '/administration',
      name: 'admin',
      component: () => import(/* webpackChunkName: "admin" */ './views/Admin/index.vue'),
      children: [
        {
          path: 'actions',
          name: 'admin_index',
          component: () => import(/* webpackChunkName: "admin" */ './views/Admin/list.vue'),
          meta: {
            logo: 'grey',
            secure: true
          }
        },
        {
          path: 'ajouter-un-talent',
          name: 'admin_create_user',
          component: () => import(/* webpackChunkName: "admin" */ './views/Admin/createUser.vue'),
          meta: {
            logo: 'grey',
            secure: true
          }
        },
        {
          path: 'les-tags',
          name: 'admin_tags',
          component: () => import(/* webpackChunkName: "admin" */ './views/Admin/setTagsForV2.vue'),
          meta: {
            logo: 'grey',
            secure: true
          }
        },
        {
          path: 'les-talents',
          name: 'admin_talents',
          component: () => import(/* webpackChunkName: "admin" */ './views/Admin/talentList.vue'),
          meta: {
            logo: 'grey',
            secure: true
          }
        },
        {
          path: 'la-team',
          name: 'admin_team',
          component: () => import(/* webpackChunkName: "admin" */ './views/Admin/teamPage.vue'),
          meta: {
            logo: 'grey',
            secure: true
          }
        },
        {
          path: 'talent/:user',
          name: 'admin_user',
          component: () => import(/* webpackChunkName: "admin" */ './views/Admin/userPage.vue'),
          children: [
            {
              path: '',
              name: 'admin_talents_profile',
              component: () => import(/* webpackChunkName: "admin" */ './views/Admin/userProfile.vue'),
              meta: {
                logo: 'grey',
                secure: true
              }
            },
            {
              path: 'CV',
              name: 'admin_talents_cv',
              component: () => import(/* webpackChunkName: "admin" */ './views/Admin/userCV.vue'),
              meta: {
                logo: 'grey',
                secure: true
              }
            },
          ],
          meta: {
            logo: 'grey',
            secure: true
          }
        },

      ],
      meta: {
        logo: 'grey'
      }
    },
    {
      path: '*',
      component: NotFound,
    },
  ],
  scrollBehavior() {
    Vue.prototype.$scrollTo('#app', 300, {
      offset: -50,
    });
  },
});
