import store from "@/store";
class Talent {
  constructor(talentFromApi: any) {
    talentFromApi.tags = talentFromApi.tags ? talentFromApi.tags.split(',') : [];
    talentFromApi.tagsOptions = talentFromApi.tags.map((tag: string) => {

      return {
        code: `tags_${tag}`,
        // @ts-ignore
        label: store.state.config.tags[tag]
      }
    })
    // talentFromApi.sectors = talentFromApi.sectors ? talentFromApi.sectors.split(',') : [];
    // talentFromApi.domains = talentFromApi.domains ? talentFromApi.domains.split(',') : [];
    talentFromApi.jobs = talentFromApi.jobs ? talentFromApi.jobs.split(',') : [];
    if (talentFromApi.jobsTexts) {
      talentFromApi.jobsTexts = talentFromApi.jobsTexts.splice(0, 3)
    }
    talentFromApi.displayName = talentFromApi.displayName.replace(/\d/g, '')
    if (!/\.$/.test(talentFromApi.displayName)) {
      talentFromApi.displayName += "."
    }
    talentFromApi.prenom = talentFromApi.displayName.split(' ')[0]
    return talentFromApi
  }
}

export default Talent;
