<template>
  <div>Gestion de la team</div>
</template>

<script>
export default {
name: "team"
}
</script>

<style scoped>

</style>
