<template>
  <div id="connexion">
    <h1>Le site DRiMSCREATiVE est en maintenance.</h1>
  </div>
</template>

<script>
export default {
  name: "Maintenance"
}
</script>

<style lang="scss">
</style>
