  const breakpoints: Breakpoint[] = [
  {
    name: 'xlarge',
    columns: 5,
    maxTalents: 25,
    maxItems: 4,
    width: 1340,
  },
  {
    name: 'large',
    columns: 4,
    maxTalents: 20,
    width: 1075,
    maxItems: 5,
  },
  {
    name: 'medium',
    columns: 3,
    maxTalents: 15,
    width: 820,
    maxItems: 3,
  },
  {
    name: 'small',
    columns: 2,
    maxTalents: 10,
    maxItems: 3,
    width: 576,
  },
  {
    name: 'xsmall',
    columns: 1,
    maxTalents: 5,
    width: 0,
    maxItems: 3,
  },
]

  export default breakpoints.sort( ( a: Breakpoint, b: Breakpoint ) => {
    // sort ASC
    if (a.width > b.width) {
      return 1;
    } else {
      return -1;
    }
  });
