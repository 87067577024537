import breakpoints from '@/assets/json/breakpoints';
import api from '@/helpers/api';
import Talent from '@/Models/Talent';
import jwt_decode from "jwt-decode"
import _debounce from 'lodash.debounce';
import Vue from 'vue';
import Vuex from 'vuex';

let API = "http://api.drimscreative.com"

const computeDatas = function (datas: any, talents: any) {
  let output = [];
  for (let tag in datas.tags) {
    if (tag) {
      output.push({
        label: datas.tags[tag],
        code: `tags_${tag}`,
      });
    }
  }
  return output;
};
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    secured: false,
    user: {
      avatar: "",
      displayName: "",
      email: "",
      role: "",
      screenName: "",
      token: '',
      type: ""
    },
    config: {},
    talents: [],
    talentsByType: {},
    filteredTalents: [],
    filteredTalentsIndex: -1,
    currentTalent: null,
    adminTalent: null,
    currentProject: null,
    appLoaded: false,
    isLoaderActive: false,
    loaderMessage: '',
    currentMedia: null,
    favoritesList: [],
    search: {
      profilType: '',
      pagination: 0,
      q: '',
    },
    breakpoint: {
      name: 'nc',
      columns: 1,
      maxTalents: 10,
      maxItems: 5,
      width: 0,
    },
    breakpoints: [],
    device: {
      isTouch: false,
      breakpoint: 'large',
      width: 0,
      height: 0,
      orientation: 'landscape',
      isDesktop: true,
      isTablet: false,
      isMobile: false,
      is: 'desktop',
    },
    tags: [
      {
        label: 'JS',
        value: 1,
      },
      {
        label: 'HTML',
        value: 2,
      },
      {
        label: 'CSS',
        value: 3,
      },
    ],
    userEmail: '',
    isFavoriteOpen: false,
    parameters: null
  },
  mutations: {
    SET_APP_LOADED(state) {
      state.appLoaded = true;
    },
    SET_CONFIG(state, config) {
      state.config = config;
    },
    SET_PARAMETERS(state, parameters) {
      state.parameters = parameters
    },
    SET_TALENTS(state, talents) {
      for (const talent of talents) {
        talent.order = Math.floor(Math.random() * 10000);
      }
      talents = talents.sort((itemA: any, itemB: any) => {
        return itemA.order > itemB.order ? 1 : -1;
      });

      talents.forEach((item: any, index: number) => {
        item.filterIndex = index
      });
      state.talents = talents;
    },

    SET_FILTERED_TALENTS(state, talents) {
      state.filteredTalents = talents
    },
    SET_TALENTS_BY_TYPE(state, talentsByType) {
      state.talentsByType = talentsByType;
    },
    SET_CURRENT_TALENT(state, talent) {
      state.currentTalent = talent;
    },
    SET_CURRENT_PROJECT(state, project) {
      state.currentProject = project;
    },
    OPEN_LOADER(state, payload) {
      state.isLoaderActive = true;
      state.loaderMessage = payload.message || '';
    },
    CLOSE_LOADER(state) {
      state.isLoaderActive = false;
      state.loaderMessage = ''
    },
    SET_CURRENT_MEDIA(state, media) {
      state.currentMedia = media;
    },
    ADD_TO_FAVORITES(state, talent) {
      let exist = state.favoritesList.find((item: any) => item.uuid === talent.uuid);
      if (!exist) {
        // @ts-ignore
        state.favoritesList.push(talent);
      }
    },
    REMOVE_FROM_FAVORITES(state, talent) {
      state.favoritesList = state.favoritesList.filter((item: any) => {
        if (item.uuid === talent.uuid) {
          return false;
        }
        return true;
      });
      localStorage.setItem('favoriteTalents', JSON.stringify(state.favoritesList))
    },
    SET_SEARCH(state, payload) {
      state.search = Object.assign({}, state.search, payload);
    },
    SET_BREAKPOINTS(state, breakpoints) {
      state.breakpoints = breakpoints;
    },
    SET_BREAKPOINT(state, breakpoint: Breakpoint) {
      state.breakpoint = breakpoint;
    },
    SET_DEVICE(state, device) {
      state.device = device;
    },
    SET_USER_EMAIL(state, email) {
      state.userEmail = email
    },
    OPEN_FAVORITES(state) {
      state.isFavoriteOpen = true
    },
    CLOSE_FAVORITES(state) {
      state.isFavoriteOpen = false
    },
    SET_SECURED(state, user) {
      if (user) {
        state.secured = true
        state.user = user
      } else {
        state.secured = false
        state.user = {
          avatar: "",
          displayName: "",
          email: "",
          role: "",
          screenName: "",
          token: '',
          type: ""
        }
      }
    },
    SET_TALENT(state, user) {
      state.adminTalent = user
    }
  },
  actions: {
    async SET_CURRENT_TALENT_BY_NAME({state, commit, dispatch}, payload) {
      if (!state.talents.length) {
        await setTimeout(() => {
          dispatch('SET_CURRENT_TALENT_BY_NAME', payload)
        }, 100)
        return false
      }

      if (typeof payload === 'string') {
        payload = {
          talent: payload
        }
      } else if (payload === undefined || payload === null) {
        payload = {
          talent: false
        }
      }

      console.log(payload)

      // @ts-ignore
      if (payload.talent) {
        let talent = state.talents.find((talent: any) => {
          if (talent.screenName === payload.talent) console.log(talent.screenName, payload.talent, talent.screenName === payload.talent)
          return talent.screenName === payload.talent
        });
        console.log(talent)
        if (talent) {
          payload.talent = talent
          await dispatch('SET_CURRENT_TALENT', payload);
        }
      } else {
        await dispatch('SET_CURRENT_TALENT', null);
      }
    },
    async SET_CURRENT_PROJECT_BY_NAME({state, commit, dispatch}, projectName) {
      if (!state.talents.length || !state.currentTalent) {
        await setTimeout(() => {
          dispatch('SET_CURRENT_PROJECT_BY_NAME', projectName)
        }, 100)
        return false
      }
      let talent: any = state.currentTalent
      // @ts-ignore
      if (state.currentProject && state.currentProject.name === projectName) {
        return false
      } else if (projectName) {
        let projet = talent.projets.find((projet: any) => {
          return projet.slug.toLowerCase() === projectName.toLowerCase()
        });
        if (projet) {
          await commit('SET_CURRENT_PROJECT', projet);
        }
      } else {
        await dispatch('SET_CURRENT_PROJECT', null);
      }
    },
    async SET_CURRENT_TALENT({commit}, payload) {
      console.log("SET_CURRENT_TALENT", payload)
      if (typeof payload === 'string') {
        payload = {
          talent: payload
        }
      } else if (payload === undefined || payload === null) {
        payload = {
          talent: false
        }
      } else if (payload.screenName) {
        payload = {
          talent: payload
        }
      }

      if (payload.talent) {
        let url = `${API}/talent/${payload.talent.screenName}/getTalentDatas/`;
        if (payload.preview) {
          url += '?preview=1'
        }
        await api.GET(url).then((res) => {
          let T = new Talent(Object.assign({}, payload.talent, res.data))
          commit('SET_CURRENT_TALENT', T);
        });
      } else {
        commit('SET_CURRENT_TALENT', null);
      }
    },
    async SEND_TALENT_INFO({state, commit}, email) {
      let talent: any = state.currentTalent;
      let url = `${API}/talent/${talent.screenName}/sendInfo/`;
      let req = await api.POST(url, {
        data: {
          email,
          tags: ""
        }
      })
      return req.data || req.response.data
    },
    async GET_TALENTS({commit, state}) {
      let url = `${API}/v2/talents/`;
      // let url = `${API}/test.json`;
      await api.GET(url)
          .then(async (res) => {
            let talents = res.data;

            if (talents && Array.isArray(talents)) {
              talents = talents.map((talent: any) => {
                return new Talent(talent);
              });
            } else {
              talents = []
            }

            await commit('SET_TALENTS', talents);
            let byType: any = {};
            for (let talent of state.talents) {
              // @ts-ignore
              if (!byType[talent.profilType]) {
                // @ts-ignore
                byType[talent.profilType] = [];
              }
              // @ts-ignore
              byType[talent.profilType].push(talent);
            }
            // byType['CREA'] = byType['CREA'].splice(0, 20)
            await commit('SET_TALENTS_BY_TYPE', byType);
          });
    },
    async GET_CONFIG({commit, dispatch, state}) {
      let url = `${API}/service/getDatas/`;
      await api.GET(url)
          .then((res) => {
            let conf = require('@/assets/json/datas.json');
            let datas = res.data;
            let output = Object.assign({}, res.data, conf);
            output.searchTag = computeDatas(datas, state.talents);
            commit('SET_CONFIG', output);
            return;
          });
    },
    async INIT_DEVICE({commit, dispatch}) {
      await commit('SET_BREAKPOINTS', breakpoints);
      await dispatch('REFRESH_DEVICE');
      window.addEventListener('resize', _debounce(() => {
        dispatch('REFRESH_DEVICE');
      }, 15));
      window.addEventListener('orientationchange', _debounce(() => {
        dispatch('REFRESH_DEVICE');
      }, 15));
    },
    async REFRESH_DEVICE({commit, state}, payload) {
      const w = window.innerWidth;
      const h = window.innerHeight;
      /* set breakpoints */
      const breakpoints: Breakpoint[] = state.breakpoints;
      let currentBreakpoint: any = null;
      for (let breakpoint of breakpoints) {
        if (w >= breakpoint.width) {
          currentBreakpoint = breakpoint;
        } else {
          break;
        }
      }
      /* set touch device */
      let touch = (
          'ontouchstart' in window
          // @ts-ignore
          || (window.DocumentTouch && document instanceof window.DocumentTouch))
          || false;
      let device = {
        isTouch: touch,
        breakpoint: currentBreakpoint.name,
        width: w,
        height: h,
        orientation: w > h ? 'landscape' : 'portrait',
        isDesktop: currentBreakpoint.name === 'xlarge' || currentBreakpoint.name === 'large',
        isTablet: currentBreakpoint.name === 'medium',
        isMobile: currentBreakpoint.name === 'small' || currentBreakpoint.name === 'xsmall',
        is: 'is-tablet',
      };
      device.is = `is-${device.isDesktop ? 'desktop' : device.isTablet ? 'tablet' : 'mobile'}`;
      if (state.breakpoint.name !== currentBreakpoint.name) {
        document.documentElement.classList.remove(state.device.is);
        document.documentElement.classList.remove(state.device.breakpoint);
        document.documentElement.classList.remove(`is-${state.device.breakpoint}`);
        document.documentElement.classList.remove(`is-${state.device.orientation}`);

        document.documentElement.classList.add(device.is);
        document.documentElement.classList.add(currentBreakpoint.name);
        document.documentElement.classList.add(`is-${currentBreakpoint.name}`);
        document.documentElement.classList.add(`is-${device.orientation}`);

        document.documentElement.classList.add(`is-${touch ? 'touch' : 'not-touch'}`);

        await commit('SET_BREAKPOINT', currentBreakpoint);
      }
      await commit('SET_DEVICE', device);
    },
    async SAVE_TALENT_TAGS({state, commit, dispatch}, payload) {
      await api.POST(`${API}/user/${payload.talent.screenName}/set-tags/`, {
        data: {
          tags: payload.tags,
          id: payload.talent.app_id
        }
      })
      await dispatch('GET_TALENTS')
    },
    async SET_TAGS_STATUS({dispatch, commit}, payload) {
      await api.POST(`${API}/user/${payload.talent.screenName}/set-tags-status/`, {
        data: payload
      })
      await dispatch('GET_TALENTS')
    },
    async DO_CONNECTION({state}, user) {
      let req = await api.POST(`${API}/v2/connexion/`, {
        data: user
      })
      return req
    },
    async SET_USER({state, commit}, user) {
      if (user) {
        let token = user.token
        let decoded: any = jwt_decode(token);
        if (decoded.sub.email === "tibolan@gmail.com") {
          // decoded.sub.role = "TALENT"
        }
        user = Object.assign({}, user, decoded.sub)
        sessionStorage.setItem('token', JSON.stringify(user))
        await commit('SET_SECURED', user)
      } else {
        sessionStorage.removeItem('token')
        await commit('SET_SECURED', null)
      }
      return user
    },
    GET_CURRENT_TALENT_BY_INDEX({state}, index) {
      state.filteredTalentsIndex = index
      return state.filteredTalents[index]
    },

    async GET_USER({state}, user) {
      let req = api.GET(`${API}/v2/user/${user}/`)
      return req
    },

    async UPDATE_USER({state}, payload) {
      let req;
      if (payload.action === "professional-informations") {
        req = api.POST(`${API}/v2/user/${payload.user}/${payload.action}/`, {
          data: payload.data,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      } else {
        req = api.PUT(`${API}/v2/user/${payload.user}/${payload.action}/`, {
          data: payload.data,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      }

      return req
    }
  },
});
