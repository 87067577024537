<template>
  <div v-if="isOpen"
       id="favoriteList"
       :class="[isOpen ? 'active' : 'inactive', favoritesList && favoritesList.length ? 'not-empty' : 'empty']"
       class="top-header"
  >
    <template v-if="device.isDesktop && favoritesList && favoritesList.length && appLoaded">
      <div class="favorite-title">
        Ma sélection
        <span v-if="!isOpen" class="favorite-length">{{ favoritesList.length }}</span>
      </div>
      <ul class="favorite-list">
        <li v-for="talent of favoritesList" class="favorite-list-item">
          <router-link :to="{name: 'talent', params: {name: talent.screenName}}" class="favorite-list-link">
            <img :src="`http://static.drimscreative.com${talent.avatar2 || talent.avatar}`"
                 class="favorite-list-item-avatar"/>
            <div class="favorite-list-item-content">
              <span class="favorite-list-item-name">{{ talent.displayName }}</span>
              <span class="favorite-list-item-job">{{ talent.jobsTexts[0] }}</span>
              <a class="favorite-list-item-close" href="#" title="Supprimer de la liste"
                 @click.prevent.stop="deleteFromFavorites(talent)"></a>
            </div>
          </router-link>
        </li>
      </ul>
    </template>
    <template v-else-if="favoritesList && favoritesList.length && appLoaded">
      <div class="favorite-title">
        Ma<br>sélection
        <span class="favorite-length-mobile">{{ favoritesList.length }} talent<template
            v-if="favoritesList.length>1">s</template></span>
      </div>
      <ul class="favorite-list">
        <li v-for="talent of favoritesList" class="favorite-list-item">
          <a @click="goToTalent(talent)" class="favorite-list-link clickable">
            <img :src="`http://static.drimscreative.com${talent.avatar2 || talent.avatar}`"
                 class="favorite-list-item-avatar"/>
            <div class="favorite-list-item-content">
              <span class="favorite-list-item-name">{{ talent.displayName }}</span>
              <span class="favorite-list-item-job">{{ talent.jobsTexts[0] }}</span>
              <a class="favorite-list-item-close" href="#" title="Supprimer de la liste"
                 @click.prevent.stop="deleteFromFavorites(talent)"></a>
            </div>
          </a>
        </li>
      </ul>
    </template>


    <span v-if="isOpen && device.isDesktop" class="favorite-toggle" @click.stop="$store.commit('CLOSE_FAVORITES')">
      <span>Fermer</span>
      <component :is="croix" height="16" width="16"></component>
    </span>
  </div>
</template>
<script>
import Card from "@/components/Card";
import ResponsiveGrid from "@/components/responsiveGrid";
import EventBus from '@/helpers/EventBus'
import {mapState} from 'vuex'

export default {
  name: 'favoritesList',
  components: {ResponsiveGrid, Card},
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['favoritesList', 'appLoaded', 'device', 'breakpoint', 'config']),
    croix () {
      return require('@/assets/svg/croix.svg')
    },
  },
  methods: {
    showTalent (talent) {
      EventBus.$emit('panel-close')
      this.$router.push({name: 'talent', params: {name: talent.screenName}})
    },
    async deleteFromFavorites (talent) {
      await this.$store.commit('REMOVE_FROM_FAVORITES', talent)
      this.$nextTick(() => {
        if (!this.favoritesList.length) {
          EventBus.$emit('panel-close', 'favorites')
        }
      })
    },
    async goToTalent (talent) {
      await EventBus.$emit('panel-close', 'favorites')
      await this.$router.push({name: 'talent', params: {name: talent.screenName}})
    }
  },
  beforeMount () {
    let savedTalents = localStorage.getItem('favoriteTalents')
    let parsedTalents = JSON.parse(savedTalents)
    if (parsedTalents) {
      for (let talent of parsedTalents) {
        this.$store.commit('ADD_TO_FAVORITES', talent)
      }
    }
  },
  watch: {
    favoritesList (v) {
      if (!v.length) {
        this.$store.commit('CLOSE_FAVORITES')
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/styles/_lists.scss";

$fx: 250ms ease-out;
#favoriteList,
.top-header {
  background-color: $dcr-blue;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000;

  &.not-empty {
    min-height: 60px;
  }

  p {
    margin: 0;
  }
}

.favorite-length {
  display: inline-block;
  background-color: white;
  box-shadow: 0 0 3px #000000;
  border-radius: 50px;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  width: 30px;
  margin-left: 15px;
}

.favorite-length-mobile {
  font-size: 18px;
}

.favorite-close {
  margin: 0;
}

.favorite-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-content: flex-end;
  grid-gap: 30px;
  margin: 30px 0 30px 180px;
  overflow: hidden;
  // transition: $fx;
}

.is-mobile .favorite-list {
  grid-template-columns: repeat(1, 1fr);
}

.favorite-title {
  white-space: nowrap;
  position: absolute;
  top: 15px;
  line-height: 30px;
}

#favoriteList.active {
  .favorite-list {
    height: 100%;
    opacity: 1;

    .favorite-list-item-name {
      font-size: 20px;
      line-height: 1;
      margin-top: 10px;
    }

    .favorite-list-item-job {
      font-size: 14px;
    }

    .favorite-list-item-avatar {
      transform: scale(1);
    }
  }

  .favorite-title {
    padding-top: 25px;
    font-size: 20px;
    transition: $fx;
  }
}

#favoriteList.inactive {
  cursor: pointer;

  .favorite-list {
    height: 0;
    opacity: 0;
    margin: 0;

    .favorite-list-item-name,
    .favorite-list-item-job {
      font-size: 0px;
    }

    .favorite-list-item-avatar {
      transform: scale(0.1);
    }
  }

  .favorite-title {
    font-size: 16px;
    transition: $fx 0ms;
  }
}

.favorite-list-item {
  position: relative;

  &:hover {
    .favorite-list-link {
      text-decoration: none;
    }

    .favorite-list-item-close {
      opacity: 1;
    }
  }
}

.favorite-list-item-close {
  display: flex;
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  line-height: 14px;
  display: block;
  text-align: center;
  padding: 0;
  transition: $fx;
  opacity: 0;
  font-size: 18px;
  font-family: monospace;
  font-weight: 600;
  border: 1px solid black;
  position: absolute;
  right: -25px;
  top: 10px;

  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: black;
    height: 1px;
    width: 10px;
    top: 50%;
    left: 50%;
  }

  &:before {
    transform: translate(-50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%) rotate(-45deg);
  }


  svg {
    margin: auto;
    transform: translateY(-1px);
  }
}

.is-mobile, .is-tablet {
  .favorite-list-item-close {
    opacity: 1;
  }
}

.favorite-list-link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 30px;

  .favorite-list-item-content {
    position: relative;
    //padding-top: 10px;
  }
}

.favorite-list-item-avatar {
  background-color: white;
  max-width: 85px;
  min-width: 85px;
  max-height: none;
  min-height: auto;
  margin-right: 20px;
  transition: $fx;
}

.favorite-list-item-name {
  display: block;
  font-weight: 500;
}

.favorite-list-item-job {
  display: block;
  font-weight: 400;
}

.favorite-list-item-job,
.favorite-list-item-name {
  transition: $fx;
  white-space: nowrap;
}

.favorite-toggle {
  cursor: pointer;
  position: absolute;
  right: 60px;
  top: 20px;
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
}

.is-mobile, .is-tablet {
  #favoriteList {
    flex-direction: column;

    .favorite-title {
      position: static;
      padding: 20px 0;
      min-width: 100%;
      font-size: 35px;
    }

    .favorite-list {
      display: flex;
      flex-direction: column;
      margin: 0;
      height: auto;

      .favorite-list-item {
        margin: 0;
      }
    }

    .favorite-list-item-content {
      flex: 1;
    }
  }
}
</style>
