<template>
  <div v-if="image || (media && media.path)" class="dcr-card">
    <a class="dcr-card-link clickable" tabindex="0" @click.prevent.stop="onClick($event)">
      <media v-if="media" :media="media" class="dcr-card-media" :alt="imageAlt"></media>
      <async-image v-else :key="image" :block="true" :no-preload="!preloadImage" :url="image"
                   class="dcr-card-image" :alt="imageAlt"></async-image>
      <div :class="smallSize" class="dcr-card-title" v-html="title"></div>
      <div class="dcr-card-text" v-html="text"></div>
    </a>
    <slot></slot>
  </div>
</template>
<script>
import AsyncImage from '@/components/AsyncImage'

export default {
  name: 'Card',
  components: {AsyncImage},
  computed: {
    smallSize () {
      return this.title.length > 30 ? 'dcr-card-title_title' : ''
    }
  },
  methods: {
    onClick (event) {
      this.$emit('click', event)
    },
  },
  props: {
    url: {
      type: [String, Object],
    },
    media: {
      type: Object
    },
    image: {
      type: String
    },
    imageAlt: {
      type: String,
      default: ''
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    preloadImage: {
      type: Boolean,
      default: true
    }
  },
}
</script>
<style lang="scss">
$offset: -10px;
.dcr-card {
  position: relative;
  cursor: pointer;
  text-align: left;


  .dcr-card-link {
    &:before {
      content: "";
      position: absolute;
      top: $offset;
      left: $offset;
      right: $offset;
      bottom: $offset;
      //
      border: 1px solid #f3f3f3;
      pointer-events: none;
      opacity: 1;
      transition: 350ms ease-out;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;

      &:before {
        opacity: 1;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, .3);
      }
    }
  }
}

.dcr-card-link, .dcr-card-link:hover, .dcr-card-link:focus {
  text-decoration: none;
}

.dcr-card-title {
  font-size: 26px;
  margin-top: 20px;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 500;
}

.dcr-card-title_title {
  font-size: 20px;
  font-weight: 500;
}

.dcr-card-text {
  font-size: 14px;
}

.dcr-card-image {
  width: 100%;
}

.responsive-grid-column .dcr-card:not(:last-child) {
  margin-bottom: 34px;
}
</style>
