<template>
  <div v-show="isActive" class="loader" :class="inline ? 'loader-inline' : 'loader-block'">
    <component :is="loader" :height="width" :width="width"></component>
    <p v-if="message && message.length">{{ message }}</p>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  data () {
    return {}
  },
  props: {
    width: {
      type: Number,
      default: 200,
    },
    message: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loader () {
      return require('@/assets/svg/loader.svg')
    },
  },
}
</script>
<style lang="scss">
.loader {
  &.loader-inline {
    display: inline-flex;
  }
}
</style>
